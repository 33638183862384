import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import styles from 'components/Support/Support.module.scss';

interface SupportTabsComponentProps {
    tab: number;
    setTab: (item: number) => void;
}

const SupportTabsComponent: React.FC<SupportTabsComponentProps> = ({ tab, setTab }) => (
  <div className="container-fluid">
    <div className={styles.supportTabsItems}>
      <div className="container">
        <ul>
          <li className={tab === 1 ? styles.active : ''}>
            <button type="button" onClick={(): void => setTab(1)}>
              <FormattedMessage id="components.support.faq" />
            </button>
          </li>
          <li className={tab === 2 ? styles.active : ''}>
            <button type="button" onClick={(): void => setTab(2)}>
              <FormattedMessage id="components.support.contacts_support" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default SupportTabsComponent;
