import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import Tutorial from './Tutorial';

const SupportTutorials: React.FC = () => (
  <Tutorial>
    <Tutorial.Container>
      <Tutorial.Title>
        <FormattedMessage id="components.support.albums_tutorials" />
      </Tutorial.Title>
      <Tutorial.Slides />
    </Tutorial.Container>
  </Tutorial>
);

export default SupportTutorials;
