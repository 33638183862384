import * as React from 'react';

import styles from 'components/Support/Support.module.scss';

interface FAQSearchProps {
  setSearchInput: (value: string) => void;
}

const FAQSearch: React.FC<FAQSearchProps> = ({ setSearchInput }) => (
  <div className={`${styles.FAQSearch} col-12 mb-5`}>
    <input onChange={(e): void => setSearchInput(e.target.value)} type="text" placeholder="Search FAQ" />
  </div>
);

export default FAQSearch;
