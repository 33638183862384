import * as React from 'react';

import styles from 'components/Support/SupportTutorials/Tutorial/Tutorial.module.scss';

import TutorialContainer from './TutorialContainer/index';
import TutorialSlides from './TutorialSlides/index';
import TutorialTitle from './TutorialTitle/index';

export interface TutorialProps extends React.FC {
    Title: typeof TutorialTitle;
    Slides: typeof TutorialSlides;
    Container: typeof TutorialContainer;
}

const Tutorial: TutorialProps = ({ children }) => (
  <div className={styles.tutorialSection}>
    {children}
  </div>
);

Tutorial.Title = TutorialTitle;
Tutorial.Slides = TutorialSlides;
Tutorial.Container = TutorialContainer;

export default Tutorial;
