import * as React from 'react';

import styles from 'components/Support/Support.module.scss';

const FAQItems: React.FC = ({ children }) => (
  <div className={`${styles.FAQItems} col-lg-6 col-12 mb-3`}>
    {children}
  </div>
);

export default FAQItems;
