import * as React from 'react';

import styles from 'components/Support/SupportTutorials/Tutorial/Tutorial.module.scss';

const TutorialTitle: React.FC = ({ children }) => (
  <h3 className={styles.tutorialTitle}>
    {children}
  </h3>
);

export default TutorialTitle;
