import * as React from 'react';

import styles from 'components/Support/Support.module.scss';

import FAQItem from './FAQItem/index';
import FAQItems from './FAQItems/index';
import FAQSearch from './FAQSearch/index';
import FAQSection from './FAQSection/index';
import FAQTitle from './FAQTitle/index';

export interface FAQProps extends React.FC {
    Title: typeof FAQTitle;
    Item: typeof FAQItem;
    Items: typeof FAQItems;
    Search: typeof FAQSearch;
    Section: typeof FAQSection;
}

const FAQ: FAQProps = ({ children }) => (
  <div className={styles.FAQSection}>
    <div className="row">
      {children}
    </div>
  </div>
);

FAQ.Title = FAQTitle;
FAQ.Item = FAQItem;
FAQ.Items = FAQItems;
FAQ.Search = FAQSearch;
FAQ.Section = FAQSection;

export default FAQ;
