import * as React from 'react';

import SupportContact from './SupportContact/index';
import SupportFAQ from './SupportFAQ';
import SupportTabsComponent from './SupportTabs/SupportTabs';
import SupportTabs from './SupportTabs/index';
import SupportTutorials from './SupportTutorials/index';

export interface SupportProps extends React.FC {
    Tutorials: typeof SupportTutorials;
    FAQ: typeof SupportFAQ;
    Contact: typeof SupportContact;
    TabsSection: typeof SupportTabs;
    Tabs: typeof SupportTabsComponent;
}

const Support: SupportProps = ({ children }) => (
  <div>
    {children}
  </div>
);

Support.Tutorials = SupportTutorials;
Support.FAQ = SupportFAQ;
Support.TabsSection = SupportTabs;
Support.Tabs = SupportTabsComponent;
Support.Contact = SupportContact;

export default Support;
