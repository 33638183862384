import ImageOneEn from 'images/support/slides/image1_en.png';
import ImageOneFr from 'images/support/slides/image1_fr.png';
import ImageTwoEn from 'images/support/slides/image2_en.png';
import ImageTwoFr from 'images/support/slides/image2_fr.png';
import ImageThreeEn from 'images/support/slides/image3_en.png';
import ImageThreeFr from 'images/support/slides/image3_fr.png';
import ImageFourEn from 'images/support/slides/image4_en.png';
import ImageFourFr from 'images/support/slides/image4_fr.png';
import ImageFiveEn from 'images/support/slides/image5_en.png';
import ImageFiveFr from 'images/support/slides/image5_fr.png';

const SliderData = [
  {
    en: {
      image: ImageOneEn,
      link: 'https://www.youtube.com/watch?v=L54reWW77x0&amp;index=4',
    },
    fr: {
      image: ImageOneFr,
      link: 'https://www.youtube.com/watch?v=EGyvnU8AZlo&amp;index=5',
    },
  },
  {
    en: {
      image: ImageTwoEn,
      link: 'https://www.youtube.com/watch?v=YHNqb19G4uQ&amp;index=1',
    },
    fr: {
      image: ImageTwoFr,
      link: 'https://www.youtube.com/watch?v=EZYqaRINiPo&amp;index=1',
    },
  },
  {
    en: {
      image: ImageThreeEn,
      link: 'https://www.youtube.com/watch?v=3OuG-cUmn7U&amp;index=2',
    },
    fr: {
      image: ImageThreeFr,
      link: 'https://www.youtube.com/watch?v=8Opdx2gm12M&amp;index=2',
    },
  },
  {
    en: {
      image: ImageFourEn,
      link: 'https://www.youtube.com/watch?v=3GnZOv2CYpk&amp;index=3',
    },
    fr: {
      image: ImageFourFr,
      link: 'https://www.youtube.com/watch?v=I6qz_kG7JkY&amp;index=3',
    },
  },
  {
    en: {
      image: ImageFiveEn,
      link: 'https://www.youtube.com/watch?v=Rs1nnQ-7654&amp;index=5',
    },
    fr: {
      image: ImageFiveFr,
      link: 'https://www.youtube.com/watch?v=dUKl0ZDgBm8&amp;index=4',
    },
  },
];

export default SliderData;
