import React, { useState } from 'react';

import styles from 'components/Support/Support.module.scss';

import Support from '../Support';

const SupportTabs: React.FC = () => {
  const [tab, setTab] = useState(1);
  return (
    <div className="container-fluid mt-2">
      <div className={styles.supportTabs}>
        <Support.Tabs tab={tab} setTab={setTab} />
        <div className="container">
          {tab === 1 && <Support.FAQ />}
          {tab === 2 && <Support.Contact />}
        </div>
      </div>
    </div>
  );
};

export default SupportTabs;
