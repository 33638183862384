import axios from 'axios';

import { SupportServicesType } from './types';

const BASE_URL = 'https://live-tzzxuq9ybh.albums.app/backend/feedback-api/v1';

const SupportServices: SupportServicesType = {
  verifyRecaptchaToken: (token) => axios.post(`${BASE_URL}/recaptcha/score`, { token })
    .then((response) => response.status).catch((error) => error.status),

  sendFeedback: (data) => axios.post(`${BASE_URL}/feedback/create`, { ...data })
    .then((response) => response.status).catch((error) => error.status),
};

export default SupportServices;
