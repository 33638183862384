import {
  CarouselProvider, Slider, Slide, ButtonBack, ButtonNext,
} from 'pure-react-carousel';
import React, { useEffect, useState } from 'react';

import 'pure-react-carousel/dist/react-carousel.es.css';
import { detectMobileOs, isBrowser } from 'helpers/common';

import styles from 'components/Support/SupportTutorials/Tutorial/Tutorial.module.scss';

import SliderData from './data';

const TutorialSlider: React.FC = () => {
  const [os, setOs] = useState<string>('');
  const [urlLang, setUrlLang] = useState<string>('en');
  useEffect(() => {
    const path = isBrowser() ? window.location.pathname : '/en/';

    setUrlLang(path);
    setOs(detectMobileOs());
  }, []);

  const slidesCount = os === 'other' ? 4 : 1;
  const lang = urlLang.includes('/en/') ? 'en' : 'fr';

  const slides = SliderData.map((slide, index) => (
    <Slide key={slide[lang].link} index={index} className={styles.slide}>
      <div className={styles.slideWrap}>
        <a href={slide[lang].link} target="_blank" rel="noreferrer">
          <img src={slide[lang].image} alt="albums tutorial" />
        </a>
      </div>
    </Slide>
  ));

  return (
    <CarouselProvider
      naturalSlideWidth={90}
      naturalSlideHeight={60}
      totalSlides={SliderData.length}
      visibleSlides={slidesCount}
      className={styles.sliderBox}
    >
      <Slider className={styles.slider}>
        {slides}
      </Slider>
      <ButtonBack className={`${styles.slideBTN} ${styles.left}`}>Back</ButtonBack>
      <ButtonNext className={`${styles.slideBTN} ${styles.right}`}>Next</ButtonNext>
    </CarouselProvider>
  );
};

export default TutorialSlider;
