import Parser from 'html-react-parser';
import * as React from 'react';

import styles from 'components/Support/Support.module.scss';

interface FAQItemProps {
  text: string;
  body: string;
}
const FAQItem: React.FC<FAQItemProps> = ({ text, body }) => {
  const [slideIsOpen, setSlideIsOpen] = React.useState<boolean>(false);
  const content = Parser(body);

  return (
    <div className={styles.articlesContent}>
      <span
        aria-label="article title"
        role="button"
        tabIndex={0}
        onClick={(): void => setSlideIsOpen(!slideIsOpen)}
        onKeyDown={(): void => setSlideIsOpen(!slideIsOpen)}
        className={styles.FAQItem}
      >
        {text}
      </span>

      {slideIsOpen && <div className={styles.articleDescription}>{content}</div>}
    </div>
  );
};

export default FAQItem;
