/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import styles from './Textarea.module.scss';

export type InputRef = HTMLTextAreaElement;

const Textarea = React.forwardRef<InputRef, React.TextareaHTMLAttributes<HTMLTextAreaElement>>(
  (props, ref) => (
    <div>
      <textarea className={styles.textarea} ref={ref} {...props} />
    </div>
  ),
);

export default Textarea;
