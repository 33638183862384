import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';

import styles from 'components/Support/Support.module.scss';
import Alert from 'components/UI/Alert';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input/input';
import Label, { LabelError, LabelText } from 'components/UI/Label';
import Textarea from 'components/UI/Textarea';

import SupportServices from '../../../services/SupportServices';

const SupportContact: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<FeedbackData>();
  const [token, setToken] = useState<Recaptcha>('');
  const [resMessage, setResMessage] = useState<ResponseMessage>();
  const [isBTNDisable, setIsBTNDisable] = useState<boolean>(false);
  const RECAPTCHA_KEY = process.env.GOOGLE_RECAPTCHA || '6Le91gUcAAAAACov137crot5GIU7Rw_XWndxpB5f';

  const onSubmit = async (inputs: FeedbackData): Promise<void> => {
    const verifyReCaptcha = await SupportServices.verifyRecaptchaToken(token);
    const data = { ...inputs };
    data.username = data.username.toLowerCase();
    setIsBTNDisable(true);

    if (verifyReCaptcha === 200) {
      const sendFeedback = await SupportServices.sendFeedback(data);
      if (sendFeedback === 200) {
        setResMessage({
          type: 'success',
          message: 'your message is sent, thank you for your feedback',
        });
        setIsBTNDisable(false);
      } else {
        setResMessage({
          type: 'danger',
          message: 'please try later',
        });
        setIsBTNDisable(false);
      }
    }
    setToken('');
  };

  return (
    <>
      <div className="row justify-content-end">
        <GoogleReCaptchaProvider useEnterprise reCaptchaKey={RECAPTCHA_KEY}>
          {!token && <GoogleReCaptcha action="feedback" onVerify={(Token: string): void => setToken(Token)} />}
          <form onSubmit={handleSubmit(onSubmit)} className={`col-lg-6 col-12 ${styles.contactForm}`}>

            <h6 className={styles.formTitle}>
              <FormattedMessage id="components.support.personal_information" />
            </h6>
            <div className="mb-4">
              <Label>
                <LabelText>
                  <FormattedMessage id="components.support.albums_username" />
                </LabelText>
                <Input name="username" ref={register({ required: false })} placeholder="Enter username here" />
              </Label>
              {errors.username && <LabelError>Username is required</LabelError>}
            </div>
            <div className="mb-4">
              <Label>
                <LabelText>
                  <FormattedMessage id="components.support.phone_number" />
                </LabelText>
                <Input name="phoneNumber" ref={register({ required: false })} placeholder="+33 6 44 55 33 42" />
                {errors.phoneNumber && <LabelError>Phone number is required</LabelError>}
              </Label>
            </div>
            <div className="mb-4">
              <Label>
                <LabelText>
                  <FormattedMessage id="components.support.email_address" />
                </LabelText>
                <Input
                  name="email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  })}
                  placeholder="Enter email here"
                />
                {errors.email && <LabelError>Valid email is required</LabelError>}
              </Label>
            </div>
            <div className="mb-2">
              <div className="mb-3">
                <Label>
                  <LabelText>
                    <FormattedMessage id="components.support.comment" />
                  </LabelText>
                  <Input name="subject" ref={register({ required: true })} placeholder="Subject" />
                  {errors.subject && <LabelError>Subject is required</LabelError>}
                </Label>
              </div>
              <Textarea name="message" ref={register({ required: true })} placeholder="Message" />
              {errors.message && <LabelError>Message is required</LabelError>}
            </div>

            {resMessage
            && (
              <Alert level={resMessage.type}>
                {resMessage.message}
              </Alert>
            )}

            <div className="d-flex justify-content-end mt-5">
              <Button type="submit" disabled={isBTNDisable || !token}>
                {isBTNDisable && <FormattedMessage id="components.support.please_wait" />}
                {!isBTNDisable && <FormattedMessage id="components.support.submit_issue" />}
              </Button>
            </div>
          </form>
        </GoogleReCaptchaProvider>
      </div>
    </>
  );
};

export default SupportContact;
