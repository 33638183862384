import React from 'react';
import { Helmet } from 'react-helmet';

import SupportComponent from 'components/Support';
import Layout from 'components/layout';

const AboutPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Support</title>
      <meta name="og:title" content="Support" />
      <meta name="twitter:title" content="Support" />
    </Helmet>
    <SupportComponent />
  </Layout>
);

export default AboutPage;
