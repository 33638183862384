import * as React from 'react';

import Support from './Support';

const SupportComponent: React.FC = () => (
  <>
    <Support>
      <Support.Tutorials />
      <Support.TabsSection />
    </Support>
  </>
);

export default SupportComponent;
