import React, { useEffect, useState } from 'react';

import { getLang } from 'helpers/common';

import { API } from 'components/Support/SupportFAQ/enums';

import FAQ from '..';

interface FAQSectionProps {
  section: FAQSection;
}

const FAQSection: React.FC<FAQSectionProps> = ({ section }) => {
  const [articles, setArticles] = useState<FAQArticles>([]);
  const isArticle = articles.length > 0;

  // eslint-disable-next-line
  useEffect((): any => {
    const lang = getLang() === 'en' ? 'en-us' : 'fr';

    const getFAQArticles = async (): Promise<void> => {
      const response = await fetch(`${API.endpoint}/${lang}/sections/${section.id}/articles`);
      const data = await response.json();

      setArticles(data.articles);
    };

    getFAQArticles();
  }, [section.id]);

  return (
    <FAQ.Items>
      <FAQ.Title>{section.name}</FAQ.Title>
      {isArticle && articles.map(
        (article: FAQArticle) => (
          <FAQ.Item
            key={article.id}
            body={article.body}
            text={article.name}
          />
        ),
      )}
    </FAQ.Items>
  );
};
export default FAQSection;
