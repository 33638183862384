import React, { useEffect, useState } from 'react';

import { isBrowser } from 'helpers/common';

import FAQ from './FAQ';
import { Key, API } from './enums';

const SupportFAQ: React.FC = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [sections, setSections] = useState<FAQSections>([]);
  const [totalArticles, setTotalArticles] = useState<FAQArticles>([]);

  const getFAQSections = async (lang: string): Promise<void> => {
    const response = await fetch(`${API.endpoint}/${lang}/categories/${Key.FAQKey}/sections`);
    const data = await response.json();

    setSections(data.sections);
  };

  const getAllArticles = async (lang: string): Promise<void> => {
    const response = await fetch(`${API.endpoint}/${lang}/categories/${Key.FAQKey}/articles.json`);
    const data = await response.json();

    setTotalArticles(data.articles);
  };

  // eslint-disable-next-line
  useEffect((): any => {
    const lang = isBrowser() && window.location.pathname.includes('/en/') ? '' : 'fr';

    getFAQSections(lang);
    getAllArticles(lang);
  }, []);

  const searchResult = totalArticles && totalArticles
    .filter((item: FAQArticle) => item.name.toLowerCase().includes(searchInput))
    .map((article: FAQArticle) => (
      <FAQ.Item
        key={article.id}
        text={article.name}
        body={article.body}
      />
    ));

  const isSection = sections.length > 0;
  const FAQSections = isSection && sections
    .map((section) => (
      <FAQ.Section
        key={section.id}
        section={section}
      />
    ));

  return (
    <FAQ>
      <FAQ.Search setSearchInput={setSearchInput} />
      {searchInput && <FAQ.Items>{searchResult}</FAQ.Items>}
      {isSection && !searchInput && FAQSections}
    </FAQ>
  );
};

export default SupportFAQ;
