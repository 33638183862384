import * as React from 'react';

import styles from 'components/Support/Support.module.scss';

const FAQTitle: React.FC = ({ children }) => (
  <h5 className={styles.FAQTitle}>
    {children}
  </h5>
);

export default FAQTitle;
